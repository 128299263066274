.header {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: baseline;
}

.headTitle {
  height: 40px;
  overflow: hidden;
  margin-right: 3px;
  margin-bottom: 3px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}
