@import '../../../../style/variables';
@import '../../../../style/tools';

.container {
  display: block;
  margin: -12px auto 26px;

  @include media-query(palm) {
    margin: -12px auto 26px;
  }

  @media screen and (max-width: 340px) and (orientation: portrait) {
    width: 100% !important;
  }
}

.imgWrapper {
  position: relative;
  overflow: hidden;
  display: block;

  @media screen and (max-width: 340px) and (orientation: portrait) {
    width: 100% !important;
  }
}

.imageType {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 2px;
}
